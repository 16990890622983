export const tabs = [
	{
		label: 'Dashboard',
		icon: 'dashboard',
		children: [],
		path: '/',
	},
	{
		label: 'User Management',
		icon: 'assignment_ind',
		children: [
			{ text: 'Users', path: 'users' },
			{ text: 'Partners', path: 'partners' },
			{ text: 'Hospitals', path: 'hospitals' },
		],
	},
	{
		label: 'Provisioning',
		icon: 'devices',
		children: [
			{ text: 'Devices', path: 'devices' },
			{ text: 'Profiles', path: 'profiles' },
			{ text: 'Hospital Floors', path: 'hospital-floors' },
		],
	},
	{
		label: 'OTA Deployment',
		icon: 'cloud_upload',
		children: [
			{ text: 'OS Deployments', path: 'os-deployments' },
			{ text: 'APP Deployments', path: 'app-deployments' },
			{ text: 'ML Model Deployments', path: 'ml-deployments' },
		],
	},
	{
		label: 'Device Management',
		icon: 'nest_heat_link_e',
		children: [
			{ text: 'Device Flavors', path: 'device-flavors' },
			{ text: 'Applications', path: 'device-apps' },
			{ text: 'OS Releases', path: 'os-releases' },
			{ text: 'APP Releases', path: 'app-releases' },
		],
	},
	{
		label: 'ML Models',
		icon: 'cognition',
		children: [
			{ text: 'Create ML Models', path: 'create-ml-models' },
			{ text: 'Upload ML Models', path: 'upload-ml-models' },
		],
	},
]
